<template>
    <div>
      <semrush-loader/>
      <Loader :loading="this.loading"/>


        <div class="is-flex ml-6">
            <h1 class="title m-5" style="margin-left: 250px !important;">
              {{$t('project.name', {name : project.emoji ? project.name + project.emoji : project.name + emoji[Math.floor(Math.random()*emoji.length)]})}}
            </h1>
        </div>

        <div class="box is-half p-6">

            <div class="columns is-mobile is-centered">
                <a @click="$router.go(-1)" class="pr-4">
                    <i class='bx bx-arrow-back' style="color:#48a9a6;"  />
                </a>
                <h2 class="column is-11 is-half pl-6">{{$t('search.list')}}</h2>
            </div>

            <div class="center">
                <search-dialog-run v-if="searchRunning"
                    @handleDialogClose="this.closeDialog"
                    :is-active="this.activeDialog"/>
                <search-dialog-create v-else
                    @handleDialogClose="this.closeDialog"
                    :is-active="this.activeDialog"
                    :id-project="this.idProject"
                    :name-project="this.project.name"
                />
            </div>

            <div class="ml-6">
                <vs-row>
                    <vs-col w="4" vs-type="flex" vs-justify="center" vs-align="center">
                        <vs-card @click="openDialog">
                            <template #img>
                                <img src="../assets/card.png" alt="">
                            </template>
                        </vs-card>
                    </vs-col>
                    <vs-col w="4" v-for="(search, index) in searchs" :key="search._id"  vs-type="flex" vs-justify="center" vs-align="center" >
                      <search-card
                          :search-status="searchStatus"
                          :search="search"
                          :index="index"
                          :project="project"
                          @delete-search="handleDelete()"
                      />
                    </vs-col>
                </vs-row>
            </div>


        </div>
    </div>
</template>

<script>
    import {getProjectById} from "@/utils/project/getProjectById";
    import {getSearchByProjectId} from "@/utils/search/getSearch";
    import { isASearchRunning } from "@/services/extensionMessaging";
    import Loader from '../components/Loader'
    import SearchCard from '../components/SearchCard'
    import SearchDialogCreate from "@/components/SearchDialogCreate";
    import SearchDialogRun from "@/components/SearchDialogRun";
    import SemrushLoader from "../components/SemrushLoader";
    import EventBus from "../services/event-bus";

    export default {
        name: "Project",
        components: {
            SemrushLoader,
            Loader,
            SearchCard,
            SearchDialogCreate,
            SearchDialogRun,
        },
        data() {
            return {
                idProject: '',
                project: {},
                searchs: [],
                primaryWord: [],
                secondaryWord: [],
                volumeMin: null,
                activeDialog: false,
                country: '',
                loading: true,
                searchRunning: false,
                emoji : ['🐣', '🦀' , '🦄','🧞', '👻', '👾', '🦅', '🤖', '🧠' , '🚀', '🦊', '🐰', '🐭', '🐱', '🐶', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵', '🐼', '🙊', '🐌', '🦋', '🕷️', '🦗', '🐝', '🐡', '🐢', '🐍', '🦎', '🦍', '🦧', '🦚', '🦜', '🦢', '🦩', '🦝', '🍀', '🍄', '🐚', '🌻', '🌸', '🌼', '🌺', '🔥', '🌈', '❄️', '☃️', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🧀', '🥑', '🍓', '🍇', '🍉', '🥝', '🍍', '🎂', '🍮', '🧁', '🍭', '🍧', '🍪', '🍩', '🍿', '🍻', '🍺', '🍷', '🍾', '🥃', '🧂', '⚽', '🏀','🏈', '⚾', '🎾', '🏐', '🏉', '🥏', '🎱', '🪀', '🏓', '🎭', '🎨', '🎬', '🎧','🎯', '🎲', '♟️'],
                searchStatus: false,
            }
        },
      created() {
        this.searchStatus = localStorage.getItem('searchStatus') == 'true'
        EventBus.$on('updateSearchStatus', this.handleUpdateSearchStatus)
      },
      beforeMount() {
            this.idProject = this.$route.params.idProject;

            getProjectById(this.idProject)
                .then(res => {
                  this.project = res.data.project
                  this.searchs = this.project.search.reverse()
                  if(!this.searchs.length){
                    this.activeDialog = true
                  }

                  this.loading = false
                })
                .catch(() => {
                  this.notificationError('An error occurred during data recovery')
                })
        },
      methods: {
        handleUpdateSearchStatus(status) {
          this.searchStatus = status
        },
        handleDelete() {
          getSearchByProjectId(this.idProject).then(res => {
            this.searchs = res.data.search.reverse()
          });
        },

        async openDialog(){
          await isASearchRunning((response) => {
              if (response){
                  this.searchRunning = response.searchStatus
              }
              this.activeDialog = true;

              console.info('Search running', this.searchRunning)
          });
        },

        closeDialog(openCreateDialog = false){
          this.activeDialog = false

          if (openCreateDialog) {
            this.searchRunning = false
            this.activeDialog = true
          }
        },
      }
    }
</script>
