<template>
  <vs-card class="search-card" @click="$router.push({name: 'Search',params: {nameProject: project.name, idSearch: search._id, reload: false}})">
    <template #title>
      <b-taglist>
        <b-tag type="is-pink"><i class='bx bx-arrow-from-bottom'/>
          <span class="span">{{ search.params.volumeMin }}</span>
        </b-tag>
        <b-tag type="is-pink"><i class='bx bx-globe-alt'/>
          <span class="span">{{ search.params.country }}</span>
        </b-tag>
      </b-taglist>

    </template>
    <template #img>
      <img src="../assets/headerCard3.png" alt="">
    </template>
    <template #text>
      <b-field grouped group-multiline class="mt-2">
        <div class="control">
          <b-taglist v-if="search.type === 'regular'">
            <b-tag type="is-primary">{{ $tc('search.keyword', 2) }} :</b-tag>
            <b-tag type="is-pink" v-for="keyword in search.primaryWord" :key="keyword">{{ keyword }}</b-tag>
          </b-taglist>
          <b-taglist v-else>
            <b-tag type="is-primary">{{$t('search.domain')}} :</b-tag>
            <b-tag>{{ search.url }}</b-tag>
          </b-taglist>
        </div>
      </b-field>
    </template>
    <template #interactions>
      <vs-button class="btn-chat" shadow primary>
        {{getSearchName(index)}}
      </vs-button>
      <b-tooltip label="Rename search" style="color: #ffffff" position="is-top">
        <vs-button
            icon
            color="warning"
            @click.stop="renameSearch(search._id)"
        >
          <i class='bx bxs-edit-alt' />
        </vs-button>
      </b-tooltip>
      <b-tooltip label="Reload search results"  style="color: #ffffff" position="is-top">
        <search-button-reload
            :disabled="searchStatus"
            :search="search"
            :project="project"
        />
      </b-tooltip>
      <b-tooltip label="Delete search" style="color: #ffffff" position="is-top">
        <vs-button
            icon
            color="danger"
            @click.stop="deleteSearch(search._id, getSearchName(index))"
        >
          <i class='bx bxs-trash'/>
        </vs-button>
      </b-tooltip>
    </template>
  </vs-card>

</template>

<script>
import {deleteSearch} from "@/utils/search/deleteSearch";
import SearchButtonReload from "./SearchButtonReload";
import {rename} from "@/utils/search/renameSearch";

export default {
  name: "SearchCard",
  components: {SearchButtonReload},
  props: {
    search: {
      type: Object
    },
    index: {
      type: Number
    },
    project: {
      type: Object
    },
    searchStatus: {
      type: Boolean
    }
  },
  methods: {
    getSearchName(index){
      return this.search.name ? this.search.name : this.$tc('search.name', 2,  {'id': index + 1})
    },
    deleteSearch(id, name) {
      this.$buefy.dialog.confirm({
        title: 'Deleting search',
        message: 'Are you sure you want to <b>delete</b> your search ?',
        confirmText: 'Delete search',
        type: 'is-danger',
        iconPack: "fas",
        icon: "exclamation-circle",
        hasIcon: true,
        onConfirm: () => {
          deleteSearch(id, this.project._id)
              .then(() => {
                this.notificationSuccess( `The search <b>${name}</b> has been deleted`)
                this.$emit('delete-search')
              })
              .catch(() => {
                this.notificationError( `Failed to delete <b>${name}</b>`)
              })

        }
      })
    },

    renameSearch(id) {
      this.$buefy.dialog.prompt({
        message: 'Send your <B>new name</B> to moon 🚀',
        inputAttrs: {
          type: 'text',
          placeholder: 'My new name is...',
          value:null
        },
        confirmText: 'Send',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (value, {close}) => {
          rename(id, value).then(() =>{
            this.search.name = value;
            setTimeout(() => {
              this.notificationSuccess( `Your new name is send`);
              close()
            }, 200)
          }).catch(() => {
            this.notificationError( `Failed to rename your search`)
          });
          this.$emit('rename-search')
        },

        type: 'is-primary',
      })
    },

  }
}
</script>

<style lang="scss">
.search-card{

  .b-tooltip[disabled]{
    pointer-events: none;
  }

}

</style>
