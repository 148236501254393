<template>
  <div>
    <vs-dialog width="500px" blur v-model="active" @close="handleClose">
      <template #header>
        <h4 class="not-margin">
          Confirm
        </h4>
      </template>


      <div class="con-content">
        <p>For now, you can't run multiple searches at the same time. By clicking on create a search, <b>you will stop the current one</b></p>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="createSearch" transparent>
            Create search
          </vs-button>
          <vs-button @click="handleClose" danger transparent>
            Cancel
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>

</template>


<script>
import { stopSearch } from "@/services/extensionMessaging";

export default {
  name: "SearchDialogRun",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      activeDialogCreate: false
    }
  },
  watch: {
    isActive(value){
       this.active = value
    },
  },
  created() {
    this.active = this.isActive
  },
  methods: {
    handleClose() {
      this.$emit('handleDialogClose')
    },
    createSearch(){
      stopSearch()
      this.$emit('handleDialogClose', true)
      this.active = false
    },
  }
}
</script>
